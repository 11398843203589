<template>
  <div>
    <eden-page-header
      title="Customer"
      :subtitle="formatName(customerName)"
      :section="'Subscriptions'"
    />
    <div>
      <eden-table-actions :title="title" :show-search="false" />
      <template>
        <p v-if="fetching">Loading</p>
        <template v-else>
          <el-table :data="pageData" style="width: 100%">
            <el-table-column min-width="400">
              <template slot="header">
                <div class="table--header">
                  <span>Subscription Cycle</span>
                  <i class="el-icon-bottom"></i>
                </div>
              </template>
              <template slot-scope="scope">
                <div class="is-flex align-center">
                  <span class="subscription-start">
                    {{ formatDate(scope.row.start_date, "ddd do, m y") }}</span
                  >
                  <i class="eden-icon-arrow-right subscription-divider"></i>
                  <span class="font-sm">
                    {{ formatDate(scope.row.end_date, "ddd do, m y") }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="200">
              <template slot="header">
                <div class="table--header">
                  <span> Services</span>
                  <i class="el-icon-bottom"></i>
                </div>
              </template>
              <template slot-scope="scope">
                <div class="is-flex is-align-center">
                  <el-tag
                    v-for="(service, i) in Object.keys(scope.row.plan)"
                    :key="i"
                    :style="{
                      background: `${setServiceType(service)}`,
                    }"
                    >{{ formatText(service) }}</el-tag
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column min-width="200">
              <template slot="header">
                <div class="table--header">
                  <span> Outstanding Amount</span>
                  <i class="el-icon-bottom"></i>
                </div>
              </template>
              <template slot-scope="scope">
                <span class="font-sm">{{
                  formatPrice(scope.row.outstanding_amount)
                }}</span>
              </template>
            </el-table-column>
            <el-table-column width="200">
              <template slot="header">
                <span class="eden-table-header">
                  Amount Charged
                  <i class="el-icon-bottom"></i>
                </span>
              </template>
              <template slot-scope="scope">
                <span class="font-sm">{{ formatPrice(scope.row.amount) }}</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import corporate from "@/requests/customers/corporate";

export default {
  name: "CustomerCorporateSubscriptionsCycles",
  data() {
    return {
      customerName: "",
      fetching: false,
      page: 1,
      pageData: [],
    };
  },
  computed: {
    title() {
      return `${this.pageData.length || 0} Subscription Cycles`;
    },
    customerId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.getCustomerSubscriptionCycles();
  },
  methods: {
    getCustomerSubscriptionCycles() {
      this.fetching = true;
      corporate
        .getSubscriptionCycles(this.customerId)
        .then((response) => {
          if (response.data.status) {
            this.pageData = response.data.data;
            this.customerName = response.data.data[0].name;
            this.fetching = false;
          }
        })
        .catch((error) => {
          this.fetching = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
.subscription-cycle {
  display: flex;
  align-items: center;

  span {
    min-width: 140px;
  }

  i {
    margin: 0 15px;
  }
}
</style>
